#contact {
    color: white;
    font-weight: bold;
    /* padding: 3%; */
    /* margin: auto 3%; */
    /* height: 70vh; */
}
#contact-title{
    font-size: 300%;
    text-align:center;
    margin:3% 0%;
}

#contact-container {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
    background: url("../../../public/img/raisin.webp") no-repeat center;
    background-size: 100%;
}
#contact-container-left {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-end;
    width: 70%;
}

#contact-container-left-child{
    font-family:"tiro telugu",sans-serif;
    font-size: 1.3rem;
    text-align:center;
    padding: 2% 2% 0% 1%;
    margin: 1%;
    background: RGB(0, 0, 0, 50%);
}
.contact-container-left-text{
    padding-bottom:3%
}
#contact-container-left-text-italic{
    font-style:italic;
}

#contact-form{
    width: 50%;
    background-color: RGB(1, 62, 80, 60%);
}

#contact-form-up {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    margin: 3%;
    align-items: center;
}

#contact-form-down-label{
    margin-bottom:3%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-form-down-textarea{
    width:80%;
    height:80%;
    background:RGB(255, 255, 255, 10%)
}

.contact-form-subtitle{
    font-size: 120%;
    padding-bottom: 10%
}

.contact-form-label{
    margin-bottom:3%
}
.contact-form-subtitle-down{
    padding-bottom:2%
}

.contact-form-input{
    padding: 8%;
    background:RGB(255, 255, 255, 10%)
}

@media screen and (max-width: 1419px){
    #contact-container{
        background:none;
        flex-direction: column;
        
    }
   
    #contact-container-left{
        align-items:center;
        background: url("../../../public/img/raisin.webp") no-repeat center;
        background-size: 100% ;
        width:100%;
        height:50%;        
    }
    #contact-container-left-child{
        font-size: .9rem;
    }

    #contact-form {
        padding: 3%;
        display: flex;
        font-size: 100%;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 50%;
        background-color: RGB(1, 62, 80, 80%);
    }
    .contact-form-subtitle {
        font-size:.9rem;
        padding-bottom: 7%;
    }
    #contact-form-up {
        height: 70%;
        flex-direction: column;
        margin: auto;
    }

    .contact-form-label{
        padding-bottom:10%
    }
   
    #contact-title{   
        padding-bottom:3%;     
        margin:auto;
        font-size:110%;
    }
    
    #contact-form-submit{
        font-size:.9rem
    }
}


