#footer{
    display:flex;
    padding-top:2rem;
    padding-left:2rem;
    justify-content:flex-start;
    height: 8rem;
    background-color: #013E50;
    color:white;
    font-weight: bold;
}

#footer-left{
    width: 33%;
}
#footer-left-logo{
    max-width:5rem
}
#footer-center{
    width:33%;
    text-align:center;
}
#footer-right{
    width:33%;
    text-align:center;
}

.footer-center-p{
    padding-bottom:2%;
}
#footer-right-img{
    display:flex;
    justify-content: center;
    flex-direction:row;
    padding-top:2%;
    margin-left:4%
}
#footer-spotify{
    width:100%
}
.footer-right-logo{
    width:50%;
   
}
.footer-right-link {
    width: 35%;
    margin: auto 5%;
}

@media screen and (max-width:700px){
    #footer{
        font-size:60%;
        flex-direction:column;
        height:18rem;
        justify-content:center;
        align-items:center;
        padding: 2%
    }

    #footer-left{
        text-align:center;
        padding-bottom:3%;
    }
    
    #footer-center{
        padding-bottom:3%
    }
    #footer-right{
        width:90%;
        text-align:center;
        
    }
    #footer-right-img{
        padding-top:6%;
    }
    
    .footer-right-logo{
        width:100%
    }
    #footer-spotify{
        width:170%;
    }
    #footer-right-link-spotify{
        padding-right:2rem;
    }

}