#home{
    font-family: 'Tiro Telugu', serif;
    /* max-height:100vh;
    max-width:100vw; */
    flex-grow:1
}

#home-up{
    /* background: url("../../../public/img/guitare.jpg") no-repeat center ; */
    background: url("../../../public/img/guitare2.webp") no-repeat center;
    background-size:100%;
    min-height:100vh;
    max-width:100vw;
    display:flex;
    flex-flow: row nowrap;
    color:white;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
}

#home-up p {
    background: rgba(0,0,0,0.2);
    width: 65%;
    margin: auto;
    padding:2%
}

#home-up-text-left {
    font-style: italic;
    width: 50%;
    margin: 7% 10%;
    padding-left: 10%;
}
#home-up-text-right {
    padding-top: 50rem;
    width: 50%;
    margin-left: 16rem;
    margin-bottom: 2rem;
    padding-bottom: 5%;
}

#home-down{
    padding: 3% 0%;
    box-shadow: inset 0px 5px 9px 0px lightgrey;
    display: flex;
}

#home-down-left {
    width: 50%;
    height: 40%;
    text-align: center;
    padding-bottom:3%;
    margin:auto;
}

#home-down-img{
    transform: rotate(-90deg);
    width:40%;
    height:40%; 
    padding:1%;
    box-shadow: rgb(0 0 0 / 7%) 0px 1px 1px, rgb(0 0 0 / 7%) 0px 2px 2px, rgb(0 0 0 / 7%) 0px 4px 4px, rgb(0 0 0 / 7%) 0px 8px 8px, rgb(0 0 0 / 7%) 0px 16px 16px;   
}

#home-down-right{
    margin-top:5rem;
    width: 50%;
    height: 50%;
    text-align: center;
    display:flex;
    flex-direction: column;
    text-align:center;
    font-size:1.3rem
}

#home-down-right-header {
    margin: 3% 0;
    font-size: 2rem;
}
#home-down-right-main {
    width: 50%;
    flex-grow: 1;
    font-size: 2rem;
    margin: auto;
}

#home-down-right-footer{    
    margin-top:3rem;
    text-decoration: none;
}

.home-navlink {
    padding: 0.5rem 1rem 0.5rem 1rem;
    color: black;
    font-style: italic;
    border: 0.1rem solid lightgray;
    text-decoration:none;
}

.home-navlink:hover{    
    color: white;
    background-color: #013E50;
}

#home-down-right-footer-sub{
    margin-top:3rem;
    width:30%
}

#home-down-right-footer-sub-up{
    padding-bottom:3rem
}

/*
Responsive
*/ 
@media screen and (max-width: 1419px){
   #home-up{
    background-size: cover;
    flex-flow: column wrap;
    width:100vw;
    height:100vh;
    margin:auto;
    padding-top:3%;
    align-items:center;
    font-size: 1.2rem;
   } 
   #home-up-text-left{
    font-size:1rem;
    text-align:center;
    height:50%;
    width:100%;
    padding: 0;
    margin:auto;
   }
   #home-up-text-right{
    font-size:1rem;
    text-align:center;
    height:50%;
    width:100%;
    padding: 0;
    margin:auto;
    display: flex;
   }

   #home-up p{    
    margin:auto;
    padding:3%;
   }

   #home-down{
    padding-top:10%;
    flex-direction:column;
    align-items:center;
   }
   #home-down-img{
    width:70%
   }
   #home-down-right{
    padding-top:3%;
    width:80%;
    margin:auto;
   }

   #home-down-right-header{
    font-size:.9rem
   }

   #home-down-right-main{
    font-size:.8rem;
    width:80%;
    margin: 3% auto;
   }
   #home-down-right-footer{
    font-size: .7rem;
    padding-bottom: 3%;
    padding-top: 5%;
    text-align: center;
    margin: auto;
   }
   #home-down-right-footer-sub{
    font-size: .7rem;
    padding-top:5%;
    width:80%;
    margin: 3% auto;
   }

   #home-down-right-footer-sub-up{
    font-size:.7rem;
    padding-bottom:10%;
   }
}