#bio{
    font-family: 'Tiro Telugu', serif;
}
#bio-up{
    border-bottom: .006rem solid #013E50;
    background: rgb(0,0,0, 2%);
    padding-top: 3%;
}

#bio-up-header {
    padding-left:3%;
    font-size: 2rem;
    margin-bottom: 3%;
    font-weight:bold;
}


#bio-up-main{
    display:flex;
    flex-direction: row;
    margin:3%;
}

#bio-up-main-img{
    padding: 1%;
    box-shadow: rgb(0 0 0 / 7%) 0px 1px 1px, rgb(0 0 0 / 7%) 0px 2px 2px, rgb(0 0 0 / 7%) 0px 4px 4px, rgb(0 0 0 / 7%) 0px 8px 8px, rgb(0 0 0 / 7%) 0px 16px 16px;
    width: 15%;
    height:10%;
    margin-left: 10%;
}

#bio-up-main-text {
    width: 50%;
    height: 50%;
    margin: auto;
    text-align: center;
    font-size: 2rem;
    font-style: italic;
}

#bio-middle{
    padding: 3% 3% 0 0;
    border-bottom: .006rem solid #013E50;
    background-color: RGB(75, 187, 182, 40%)
}

#bio-middle-header {
    text-align: end;
    font-size: 1.8rem;
    font-weight:bold;
    width: 30%;
    margin: 0 0 5% 70%;
}

#bio-middle-main{
    display:flex;
    flex-direction: row;
}

#bio-middle-main-img{
    padding: 1%;
    background:white;
    box-shadow: rgb(0 0 0 / 7%) 0px 1px 1px, rgb(0 0 0 / 7%) 0px 2px 2px, rgb(0 0 0 / 7%) 0px 4px 4px, rgb(0 0 0 / 7%) 0px 8px 8px, rgb(0 0 0 / 7%) 0px 16px 16px;
    width:28%;
    margin-left:40%;
}

#bio-middle-main-left{
    margin:auto;
    text-align:center;
    font-size:.8rem
}
#bio-middle-main-right{
    width:50%;
    margin:auto;
    text-align: center;
}
#bio-middle-footer {
    padding: 3% 0;
    text-align: center;
    font-size: 1.9rem;
    width: 50%;
    margin: auto;
}

#bio-down{
    margin : 3%;
}

#bio-down-header{
    font-weight:bold;
    font-size:1.9rem;
    text-align: center;
    margin-bottom: 5%;
}

#bio-down-main{
    display:flex;
    flex-direction: row;
}

#bio-down-main-iframe{
    margin: 0 5%;
    text-align:center;
    font-size:.8rem
}

#bio-down-main-text {
    font-size: 2rem;
    height: 50%;
    margin: auto;
    text-align: center;
}

.bio-iframe{
    width:720px;
    height: 450px;
}
/*
Responsive
*/ 
@media screen and (max-width: 1419px){
    #bio{
        margin:auto;
        font-size:1.5rem
    }
    #bio-up{
        margin:auto;
    }
    #bio-up-header{
        font-size: .9rem;
        text-align:center
    }
    #bio-up-main{
        flex-direction:column;
        margin:0;
        align-items:center;
    }
    #bio-up-main-img{
        width:30%;
        margin:auto
    }
    #bio-up-main-text{
        font-size: .8rem;
        width:70%;
        margin: auto;
        padding:3% 0;
    }
    #bio-middle-main{
        flex-direction:column;
        align-items:center
    }

    #bio-middle-header{     
        font-size: .9rem;   
        font-family: "tiro telugu", sans-serif;
        text-align:center;
        width:80%;
        margin:auto;
        padding-bottom:3%;
    }

    #bio-middle-main-left{
        margin:auto;
        padding-bottom:3%;
    }
    #bio-middle-main-img{
        width:60%;
        margin:auto;
        padding-bottom:3%;
    }
    #bio-down{
        margin:auto;
    }

    #bio-middle-footer{     
        font-size:.8rem;   
        width:80%;
        margin:auto;
        padding-bottom: 3%;
    }
    #bio-down-main{
        flex-direction:column
    }
    #bio-down-header{
        font-size:.9rem;
        width:80%;
        margin:auto;
        padding-bottom:3%;
        padding-top:3%;
    }
    #bio-down-main-iframe{        
        margin:auto;
    }

    #bio-down-main-text{
        font-size:.8rem;
        width:80%;
        margin:auto;
        padding: 3% 0;
    }
    
    #bio-middle-main-iframe{
        margin:0;
    }
    #bio-down-main-iframe{
        margin:0;
    }
    .bio-iframe{
        width:60vw;       
    }

}

@media screen and (max-width: 700px){
    .bio-iframe{
        height:auto;
    }
}


