#navbar {
    display: flex;
    justify-content: space-around;
    height: 3.5rem;
    padding: 2% 0;
    border-bottom: 0.006rem solid #013E50;
}


#navbar-name{ 
    padding-top: .6%;
    font-size: 2rem;
}

#navbar-text {
    color: #013E50;
    font-size: 1.5rem;
    padding-top: .9%;
    font-style: italic;
}

.navbar-navlink{
    text-decoration: none;
    padding-top: .6%;
}

#navbar-burger{
    display: none;
}

@media screen and (max-width: 1419px){
    #navbar{
        height: 6rem;
        text-align:center;
        padding:3%;
    }
    #navbar-burger{        
        display:block;      
    }
   
    .navbar-navlink{
        display:none;
    }
    #navbar-text{
        display:none
    }
    #navbar-responsive-header{
        display:flex;
        justify-content:center;
        height:3rem;
    }
    
    #navbar-responsive{
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        background:#013E50; 
        position:fixed;
        top:0;
        width: 100vw;
        height:100vh;
        margin:auto;
        z-index:99;
        padding:3%;
        
    } 
   
    
    #navbar-responsive-links{
        font-size:1rem;
        width:100%;
        height:100%;
        margin:auto;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content: center;
        gap:10%;
    }
    #navbar-name{
        width:100%;
        font-size:1rem;
        margin:auto;
        flex-grow:1;
    }
    .navbar-responsive-link{
        text-decoration:none;
        margin-bottom:3%;
        border-bottom: 3px solid white;
    }
}